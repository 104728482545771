<template>
  <div>
    <Pane />

    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-info.png" alt="" />
          <span>基本信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经办部门">
              <div class="value">{{ detail.operationDeptName }}</div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经办人">
              <div class="value">
                {{ selectedUser.name }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="关联投标及项目委托">
              <div class="value">
                {{ selectedInfo.projectName }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同编号">
              <div class="value">
                {{ newDesignCode }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同名称">
              <div class="value">
                {{ detail.name }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同金额(万元)">
              <div class="value">
                <Money :money="detail.contractAmount" />
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="关联合同">
              <div class="value">
                {{ selectedContract.name }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="业主方">
              <div class="value">
                {{ selectedCompany.name }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="用章名称">
              <div class="value">
                <span class="left" v-if="typeof detail.sealType === 'string'">
                  <span class="left" v-for="(item, index) in detail.sealType.split(',')" :key="item">
                    <DataDictFinder dictType="sealType" :dictValue="item" />
                    <span v-if="index !== detail.sealType.split(',').length - 1">/</span>
                  </span>
                </span>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="selectedInfo.projectName === '无'">
            <a-form-item label="说明" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <div class="value">
                {{ detail.bidApprovalInstruction }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-project.png" alt="" />
          <span>项目信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="责任部门">
              <div class="value">
                {{ detail.productionUnitName }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="项目负责人">
              <div class="value">
                {{ selectedCharger.name }}
              </div>
              <div class="tip">
                <a-icon type="question-circle" />
                请按照招标文件及合同中人员填写
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属区域">
              <div class="left value">
                <span class="left" v-for="(item, index) in area" :key="index">
                  {{ item }}
                  <span v-if="index !== area.length - 1">/</span>
                </span>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="总投资(万元)">
              <div class="value">
                <Money :money="detail.investment" />
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="项目地点">
              <div class="left value">
                <span class="left" v-for="(item, index) in location" :key="index">
                  {{ item }}
                  <span v-if="index !== location.length - 1">/</span>
                </span>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="国外">
              <div class="value">
                {{ detail.isAbroad }}
              </div>
              <div class="tip">
                <a-icon type="question-circle" />
                项目地点在国外时填写
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同性质">
              <div class="value">
                {{ selectedProperty.name }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="联合体单位" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <div class="value">
                {{ detail.unionType }}
              </div>

              <div class="tip">
                <a-icon type="question-circle" />
                注明在联合体中的地位
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="是否集团项目">
              <div class="value">
                <span v-if="detail.isGroup === 1">是</span>
                <span v-if="detail.isGroup === 0">否</span>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="服务内容" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <div class="left value" v-if="typeof detail.phase === 'string'">
                <span class="left" v-for="(item, index) in detail.phase.split(',')" :key="index">
                  <DataDictFinder dictType="phase" :dictValue="item" />

                  <span v-if="index !== detail.phase.split(',').length - 1">/</span>
                </span>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-scale.png" alt="" />
          <span>专业规模</span>
        </div>

        <Padding size="large" />

        <a-form-item label="专业规模" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <div style="margin-top: 4px">
            <ScaleList :list="scaleList" @remove="(index) => scaleList.splice(index, 1)" :disabled="true" />
          </div>
        </a-form-item>

        <a-form-item label="工程概况" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <div class="value">
            {{ detail.scale }}
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-collection.png" alt="" />
          <span>合同收款项</span>
        </div>

        <Padding size="large" />

        <a-form-item label="合同收款项" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <div style="margin-top: 4px">
            <CollectionList :money="detail.contractAmount / 10000" :list="collectionList" :disabled="true" />
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-file.png" alt="" />
          <span>附件</span>
        </div>

        <Padding size="large" />

        <a-form-item label="附件" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <div style="margin-top: 4px">
            <a-table bordered :dataSource="fileList" :pagination="false">
              <a-table-column title="附件名称">
                <template slot-scope="text">
                  <a :href="text.completePath" target="_blank">
                    {{ $getFileName(text.completePath) }}
                  </a>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="center">
        <a-space>
          <a-button @click="$router.go(-1)">关闭</a-button>
        </a-space>
      </div>
    </a-form>

    <ExtraInfo />
  </div>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import city from "@/mixins/city";

import { fetchDetail } from "@/api/contract-register";

import ScaleList from "./components/scale-list";
import CollectionList from "./components/collection-list";
import ExtraInfo from "./components/extra-info";

import { mapGetters } from "vuex";

export default {
  mixins: [area, organization, watermark, city],

  components: {
    ScaleList,
    CollectionList,
    ExtraInfo,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selectedUser: {}, // 选择的经办人
      selectedInfo: {}, // 关联投标及项目委托
      selectedProject: {}, // 选择的项目，用来生成合同编号
      selectedContract: {},
      newDesignCode: "", // 后端生成的设计编号
      selectedCompany: {}, // 选择的业主方

      selectedCharger: {}, // 选择的项目负责人

      scaleList: [{}], // 规模列表
      collectionList: [{}, {}, {}], // 收款项列表
      fileList: [], // 附件列表

      saveLoading: false,
      id: null, // 重新提交时的 ID
      detail: {},
      area: [],
      location: [],

      selectedProperty: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    contractCategoryList() {
      return this.findDataDict("contractCategory");
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail({ id }).then((res) => {
        console.log("detail", res);
        if (res) {
          this.detail = res;
          // 经办
          this.selectedUser = {
            userId: res.operatorId,
            name: res.operatorName,
          };

          // 关联投标及项目委托
          this.selectedInfo = {
            id: res.bidApprovalPid,
            projectName: res.bidApprovalPname,
          };

          // 合同
          this.newDesignCode = res.code;
          this.selectedProject = {
            projectCode: res.projectCode,
          };

          this.selectedContract = {
            name: res.relationContractName,
          };

          // 业务方
          this.selectedCompany.id = res.partyAid;
          this.selectedCompany.name = res.partyAname;

          // 项目负责人
          this.selectedCharger.userId = res.chargerId;
          this.selectedCharger.name = res.chargerName;

          const area = [];
          if (res.areaName) {
            area.push(res.areaName);
          }
          if (res.subAreaName) {
            area.push(res.subAreaName);
          }
          if (res.l3AreaName) {
            area.push(res.l3AreaName);
          }
          this.area = area;

          // 项目地点
          const location = [];
          if (res.belongProvince) {
            location.push(res.belongProvince);
          }
          if (res.belongCity) {
            location.push(res.belongCity);
          }
          if (res.district) {
            location.push(res.district);
          }

          this.location = location;

          for (let i = 0; i < this.contractCategoryList.length; i++) {
            const item = this.contractCategoryList[i];
            const obj = item.children.find(
              (element) => element.value === res.property
            );
            if (obj) {
              this.selectedProperty = obj;
            }
          }

          this.scaleList = Array.isArray(res.contractScaleList)
            ? res.contractScaleList
            : [];
          this.collectionList = Array.isArray(res.planReceiptList)
            ? res.planReceiptList
            : [];
          this.fileList =
            typeof res.attachments === "string"
              ? res.attachments.split(",").map((item) => {
                  return {
                    completePath: item,
                  };
                })
              : [];
        }
      });

      return;
    } else {
      alert("URL 错误！");
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
    color: #1890ff;
    font-weight: bold;
    font-size: 14px;

    img {
      width: 14px;
      margin-right: 4px;
    }

    .extra {
      margin-left: auto;
    }
  }

  .tip {
    color: #999;
  }
}

.value {
  margin-top: 4px;
  border: 1px solid #d9d9d9;
  min-height: 30px;
  line-height: 30px;
  padding: 0 15px;
  box-sizing: content-box;
  border-radius: 2px;
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>
